
import Icon from '@/components/Icon.vue'
import TheLiveChat from '@/modules/membership/components/TheLiveChat.vue'
import TheLiveNotice from '@/modules/membership/components/TheLiveNotice.vue'
import TheLiveVideo from '@/modules/membership/components/TheLiveVideo.vue'
import useLiveRoom from '@/modules/membership/live'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Live',
  components: {
    Pic,
    PageWithHeader,
    TheLiveChat,
    TheLiveNotice,
    TheLiveVideo,
    Icon,
  },
  props: {
    liveId: {
      type: String,
      required: true,
    },
    chatId: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: true,
    },
    tip: {
      type: String,
      required: true,
    },
  },
  setup (props, ctx) {
    const {
      liveAddress,
      liveReady,
      chatConfig,
    } = useLiveRoom(props.liveId, props.chatId)

    const fullscreen = () => {
      window.open(`#/blank/live?liveId=${props.liveId}&chatId=${props.chatId}&user=${props.user}&tip=${props.tip}&poster=${ctx.attrs.poster}&time=${ctx.attrs.time}&desc=${ctx.attrs.desc}`)
      ctx.emit('close')
    }

    return {
      liveAddress,
      liveReady,
      chatConfig,
      fullscreen,
    }
  },
})
